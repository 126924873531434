angular
    .module('annexaApp')
    .component('annexaBoxNotifications',{
        templateUrl: './components/reg/annexa-box-notifications/annexa-box-notifications.html',
        controller:['AnnexaFormlyFactory', 'NotificationFactory', '$scope', 'GlobalDataFactory', '$rootScope', function(AnnexaFormlyFactory, NotificationFactory, $scope, GlobalDataFactory, $rootScope) {
            var vm = this;

            $scope.$on('created_multiple_notifications', function (event, args) {
            	var isThisBox = false;
            	if(args.parentIsModal){
            		if(args.parentIsModal == "isModal" && vm.isModal){
            			isThisBox = true;
            		}else if(args.parentIsModal == "isNotModal" && !vm.isModal){
            			isThisBox = true;
            		}
            	}
            	if(isThisBox){
	                if(vm.search && vm.search.saveSearch && !vm.isModal) {
	                    vm.search.saveSearch(args.notifications);
	                }else{
	                    _.forEach(args.notifications, function (item) {
	                        vm.content.push(item);
	                    });
	                }
            	}
            });
            
            $scope.$on('update_documents_lists', function (event, args) {
            	if(vm.origin == 'tram'){
            		if(args.notification && args.notification.data && args.notification.data.dossierTransaction) {
                		vm.config.updateDocumentsFunction(args.notification.data.dossierTransaction);
                	}
            	} else {
            		if(args.notification && args.notification.data && args.notification.data.registerEntry) {
                		vm.config.updateDocumentsFunction(args.notification.data.registerEntry);
                	}
            	}
            });

            this.$onInit = function () {
                vm.regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();
                vm.notificationModule = true;
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality){
                    vm.notificationModule = $rootScope.app.configuration.show_notification_functionality.value;
                }
                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.notifications';
                }

                if(!vm.new) {
                    vm.new = {};
                }

                vm.new.newFunc = function() {
                    var newModal =  NotificationFactory.notificationNewModal(
                        vm.config.thirdBox ? angular.copy(vm.config.thirdBox.content) : undefined,
                        vm.config.documentBox ? angular.copy(vm.config.documentBox.content) : undefined,
                        vm.config.dossier ? angular.copy(vm.config.dossier) : undefined,
                        vm.config.registerEntry ? angular.copy(vm.config.registerEntry) : undefined
                    );

                    if(vm.config.dossierTransactionId) {
                    	newModal.dossierTransactionId = vm.config.dossierTransactionId;
                    }
                    
                    var submitNewNotification = function () {
                        NotificationFactory.getNewNotifications(this, newModal, vm.isModal);
                    }

                    newModal.submitModal = submitNewNotification;

                    AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', newModal);
                };
            };
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@',
            isModal:'=?'
        }
    })
    .component('annexaBoxNotificationsRender', {
        templateUrl: './components/reg/annexa-box-notifications/annexa-box-notifications-render.html',
        controller: ['$state', 'NotificationFactory', 'Language', 'AnnexaFormlyFactory', 'DialogsFactory', function($state, NotificationFactory, Language, AnnexaFormlyFactory, DialogsFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            vm.notificationGlobalStatus = NotificationFactory.notificationGlobalStatus;

            vm.isDiligenceWindow = function() {
                return $state.current.name == 'annexa.reg.input.diligences.edit';
            }
            
            vm.viewNotification = function (notification) {
                var notificationViewModal = NotificationFactory.notificationViewModal(notification, vm.origin);

                AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);
            }

            vm.getIconClassGlobalStatus = function (notification) {
                switch (notification.globalStatus) {
                    case 'PENDING':
                        return 'text-warn';
                    case 'SEND':
                        return 'text-info';
                    case 'COMPLETE':
                        return 'text-success';
                    case 'ERROR':
                        return 'text-danger';
                }

                return '';
            }

            vm.delete = function (index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteNotification')
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxNotificationsDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function(data) {
                    //Empty
                })
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })