angular
    .module('annexaApp')
    .component('annexaNotificationStatusesEvidences', {
        templateUrl: './components/reg/annexa-notification-statuses-evidences/annexa-notification-statuses-evidences.html',
        controller: ['$rootScope', 'Language', '$filter', 'AnnexaFormlyFactory', 'NotificationFactory', 'RestService', 'CommonService', '$scope', function ($rootScope, Language, $filter, AnnexaFormlyFactory, NotificationFactory, RestService, CommonService, $scope) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            var getLastStatus = function(notificationThird) {
                return $linq($linq(notificationThird.statuses).orderByDescending("x => x.createdDate").toArray()).firstOrDefault(undefined);
            }

            vm.thirdName = function(third, address) {
                return CommonService.getThirdCompleteNameWithAddress(third, address);
            }

            vm.lastStatus = function (notificationThird) {
                var lastStatus = getLastStatus(notificationThird);

                return lastStatus ?
                    lastStatus.status[Language.getActiveColumn()] + (lastStatus.observations ? ' (' + lastStatus.observations + ')' : '' ) :
                    $filter('translate')('global.literals.unknown');
            }

            vm.showChangeStatus = function (notificationThird) {
                if(!vm.canEdit) {
                    return false;
                }

                var lastStatus = getLastStatus(notificationThird);

                return !vm.notification.notificationType.allowManualStatusChange ?
                    false :
                    (lastStatus ? lastStatus.status.globalStatus != 'COMPLETE' : true);
            }

            vm.viewEvidence = function (evidence, name) {
            	var windowReference = window.open();
                NotificationFactory.getEvidence(evidence)
                    .then(function (data) {
                    	windowReference.location = data.url;
                    }).catch(function (error) {
                        console.error(error);
                        windowReference.close();
                });
            }

            var updateComponentStatus = function(data, modal, isDelete, index) {
                var indexThird = $linq(vm.notification.thirds).indexOf("x => x.id == " + data.third.id);

                if (indexThird != -1) {
                    var indexStatus = $linq(vm.notification.thirds[indexThird].statuses).indexOf("x => x.id == " + data.id);

                    if (indexStatus != -1) {
                        if(!isDelete) {
                            vm.notification.thirds[indexThird].statuses[indexStatus].createdDate = data.createdDate;
                            vm.notification.thirds[indexThird].statuses[indexStatus].evidences = data.evidences;
                        } else {
                            vm.notification.thirds[indexThird].statuses[indexStatus].evidences.splice(index, 1);
                        }
                    }
                }

                var indexFieldEvidences = $linq(modal.fieldsDefinition).indexOf("x => x.id == 'evidences'");

                if (indexFieldEvidences != 1) {
                    if(!isDelete) {
                        modal.fieldsDefinition[indexFieldEvidences].data.value = data.evidences;
                    }
                }
            }
            
            var updateDocumentsLists = function() {
            	RestService.findOne('Notification', vm.notification.id)
	                .then(function(data) {
	                	$rootScope.$broadcast('update_documents_lists', { notification: JSOG.decode(data)});
	                })
            }

            vm.editChangeState = function (status) {
                status.createdTime = status.createdDate;
                status.newEvidence = undefined;
                
                var editChangeStateModal = {
                    isEditable: true,
                    inDivRow: true,
                    hideSubmit: true,
                    closeLabel: 'global.literals.close',
                    title: 'global.literals.changeStateModify',
                    size: '',
                    fieldsDefinition: [
                        { type: 'field', id: 'state', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.state', className: 'col-lg-12',
                            data: {
                                value: status.status[Language.getActiveColumn()],
                                type: 'text',
                                rowClass: 'm-b-xs'
                            }
                        },
                        { type: 'field', id: 'createdDate', fieldType: 'date', className: 'col-lg-12', required: true, label: 'global.literals.date' },
                        { type: 'field', id: 'createdTime', fieldType: 'time', className: 'col-lg-12', required: true, label: 'global.literals.time' },
                        { type: 'field', id: 'observations', fieldType: 'textarea', className: 'col-lg-12', required: true, label: 'global.literals.observations' },
                        { type: 'field', id: 'evidences', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.evidences', className: 'col-lg-12',
                            data: {
                                value: status.evidences,
                                type: 'base64FileList',
                                valueProperty: 'name',
                                deleteFunc: function (item, index) {
                                    RestService.delete('./api/reg/notification_third_status/' + status.id + '/delete_evidence/' + item.id)
                                        .then(function (data) {
                                            updateComponentStatus(status, editChangeStateModal, true, index);
                                            updateDocumentsLists()
                                        }).catch(function (error) {
                                            console.error(error);
                                    });
                                }
                            }
                        },
                        { type: 'field', id: 'newEvidence', fieldType: 'component', componentType: 'annexa-file-upload', label: 'global.literals.addEvidences', className: 'col-lg-12',
                            data: {
                                icon: 'fa fa-plus',
                                colClass: 'col-xs-12',
                                file: status.newEvidence,
                                origin: 'modalEditChangeState',
                                changed: function (file) {
                                    RestService.update('./api/reg/notification_third_status/' + status.id + '/add_evidence', file)
                                        .then(function(data) {
                                            updateComponentStatus(data, editChangeStateModal);
                                            updateDocumentsLists();
                                        }).catch(function (error) {
                                            console.error(error);
                                    });
                                }
                            }
                        }
                    ],
                    alerts: [],
                    submitModal: function(val, prop) {
                        var newModel = angular.copy(this.model);
                        newModel.status = newModel.status ? { id: newModel.status.id } : undefined;
                        newModel.third = newModel.third ? { id: newModel.third.id } : undefined;
                        delete newModel.createdTime;

                        if(prop == 'createdDate' || prop == 'createdTime') {
                            newModel.createdDate = new Date(Date.UTC(
                                prop == 'createdDate' ? val.getFullYear() : new Date(this.model.createdDate).getFullYear(),
                                prop == 'createdDate' ? val.getMonth() : new Date(this.model.createdDate).getMonth(),
                                prop == 'createdDate' ? val.getDate() : new Date(this.model.createdDate).getDate(),
                                prop == 'createdDate' ? new Date(this.model.createdTime).getUTCHours() : val.getUTCHours(),
                                prop == 'createdDate' ? new Date(this.model.createdTime).getUTCMinutes() : val.getUTCMinutes()
                            ));
                        } else {
                            newModel[prop] = val;
                        }

                        RestService.update('./api/reg/notification_third_status/' + this.model.id, newModel)
                            .then(function(data) {
                                updateComponentStatus(data);
                            }).catch(function (error) {
                                console.error(error);
                        })
                    },
                    object: status
                }

                AnnexaFormlyFactory.showAnnexaFormModal('modalEditChangeState', editChangeStateModal);
            }


            vm.changeState = function(notificationThird) {

                var allowedStatuses = function() {
                    var usedStatuses = $linq(notificationThird.statuses).select("x => x.status").toArray();

                    return $linq(vm.notification.notificationType.statuses).except(usedStatuses, "(x,y) => x.id == y.id").orderBy("x => x.orderView").toArray();
                }

                var changeStateModal = {
                    title: 'global.literals.changeState',
                    size: '',
                    fieldsDefinition: [
                        { type: 'field', id: 'status', fieldType: 'select', data: allowedStatuses(), colClass: 'col-sm-6', required: true, label: 'global.literals.state' },
                        { type: 'field', id: 'createdDate', fieldType: 'date', colClass: 'col-sm-3', required: true, label: 'global.literals.date', defaultValue: new Date()},
                        { type: 'field', id: 'createdTime', fieldType: 'time', colClass: 'col-sm-3', required: true, label: 'global.literals.time', defaultValue: new Date() },
                        { type: 'field', id: 'observations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.literals.observations' },
                        { type: 'field', id: 'evidences', fieldType: 'component', colClass: 'col-sm-12', componentType: 'annexa-label-value', label: 'global.literals.evidences', className: 'col-lg-12',
                            data: {
                                title: 'global.literals.evidences',
                                labelStrong: true,
                                rowClass: 'row',
                                value: [],
                                type: 'base64FileList',
                                valueProperty: 'filename',
                                deleteFunc: function (item, index) {
                                    var indexFieldEvidences = $linq(changeStateModal.fieldsDefinition).indexOf("x => x.id == 'evidences'");

                                    if (indexFieldEvidences != 1) {
                                        changeStateModal.fieldsDefinition[indexFieldEvidences].data.value.splice(index, 1);
                                    }
                                }
                            }
                        },
                        { type: 'field', id: 'newEvidence', fieldType: 'component', colClass: 'col-sm-12', componentType: 'annexa-file-upload', label: '', className: 'col-lg-12',
                            data: {
                                colClass: 'col-xs-12',
                                file: undefined,
                                origin: 'modalChangeState',
                                changed: function (file) {
                                    var indexFieldEvidences = $linq(changeStateModal.fieldsDefinition).indexOf("x => x.id == 'evidences'");

                                    if (indexFieldEvidences != 1) {
                                        changeStateModal.fieldsDefinition[indexFieldEvidences].data.value.push(file);
                                    }
                                }
                            }
                        }
                    ],
                    alerts: [],
                    submitModal: function () {
                        this.form.$setSubmitted();

                        if(this.form.$valid) {
                            var evidences = undefined;

                            var indexFieldEvidences = $linq(changeStateModal.fieldsDefinition).indexOf("x => x.id == 'evidences'");

                            if (indexFieldEvidences != 1) {
                                evidences = $linq(changeStateModal.fieldsDefinition[indexFieldEvidences].data.value).select(function(x) {
                                    return { evidence: JSON.stringify(x), name: x.filename }
                                }).toArray();
                            }

                            var request = {
                                third: { id: notificationThird.id },
                                status: { id: this.model.status },
                                createdDate: this.model.createdDate && this.model.createdTime ?
                                    new Date(Date.UTC(
                                        this.model.createdDate.getFullYear(),
                                        this.model.createdDate.getMonth(),
                                        this.model.createdDate.getDate(),
                                        this.model.createdTime.getUTCHours(),
                                        this.model.createdTime.getUTCMinutes())) : undefined,
                                observations: this.model.observations ? this.model.observations : undefined,
                                evidences: evidences
                            }

                            NotificationFactory.createNotificationThirdStatus(request)
                                .then(function(data) {
                                    var indexThird = $linq(vm.notification.thirds).indexOf("x => x.id == " + data.third.id);

                                    if(indexThird != -1) {
                                        vm.notification.thirds[indexThird].statuses.push(data);
                                    }

                                    vm.notification.globalStatus = data.third.notification.globalStatus;

                                    $rootScope.$broadcast('docContentLoaded', { id: vm.notification.id, globalStatus: data.third.notification.globalStatus});
                                    $rootScope.$broadcast('reloadNotificationTable', {});
                                    updateDocumentsLists();
                                    changeStateModal.close();
                                }).catch(function(error) {
                                    changeStateModal.alerts.push({ msg: 'global.errors.unknownNew' });
                            })
                        }
                    }
                }

                AnnexaFormlyFactory.showAnnexaFormModal('modalChangeState', changeStateModal);
            }

            vm.toggleOpen = function (item) {
                if(!item.hasOwnProperty('isOpen')) {
                    item.isOpen = false;
                }

                item.isOpen = !item.isOpen;
            }

            $scope.$on('annexaFileUploadBase64Change', function(event, args) {
                vm.modalChangeState.annexaFormly.fields[2].fieldGroup[0].data.value = args.files;
            })
        }],
        bindings: {
            notification: '=',
            canEdit: '=?'
        }
    })