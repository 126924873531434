angular
    .module('annexaApp')
    .component('annexaBoxObjectReg',{
        templateUrl: function($element, $attrs) {
            return (($attrs.isEdit == 'false' || $attrs.isEditModal == 'false') ?
                './components/reg/annexa-box-object-reg/annexa-box-object-reg.html' :
                './components/reg/annexa-box-object-reg/annexa-box-object-reg-edit.html')
        },

        controller: ['RegFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'ThirdFactory', 'AnnexaBoxDossierFactory', 'NotificationFactory', 'TramFactory', 'globalModals', 'AnnexaEntityFactory', 'ModalService', 'TerritoryFactory',
        	function (RegFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, ThirdFactory, AnnexaBoxDossierFactory, NotificationFactory, TramFactory, globalModals, AnnexaEntityFactory, ModalService, TerritoryFactory) {

            //region General

            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.loggedUser = $rootScope.LoggedUser;

            //endregion

            //region Form

            var hideUniqueWindow = function($viewValue, $modelValue, scope) {
                return !scope.model.uniqueWindow_1;
            }

            var showIfUniqueWindow = function(model) {
                return model.uniqueWindow;
            }

            var showIfRegisterEntryOffice = function(model) {
                return $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active;
            }

            var getColClass = function(id) {
                if(vm.isModal) {
                    switch (id) {
                        case 'registerEntryChannel':
                        case 'documentDate':
                        case 'registerEntryOffice':
                            if ($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
                                return 'col-sm-4'
                            } else {
                                return 'col-sm-6'
                            }
                            break;
                        case 'entryDocumentType':
                        case 'entryClassification':
                            return 'col-sm-6'
                    }
                }

                return 'col-sm-12';
            }

            // Aqui nos creamos el formulario para un nuevo registro de entrada.
            if(vm.registerEntry && vm.registerEntry.presentationDate){
                vm.registerEntry.presentationTime = vm.registerEntry.presentationDate;
            }
            if (vm.registerEntry && vm.registerEntry.effectiveDate) {
            	vm.registerEntry.effectiveTime = vm.registerEntry.effectiveDate;
            }
            
            vm.newRegisterEntryInputFormFields = [
            	
                { type: 'predefined', predefined: 'profile', id: 'profile', data: 'loggedUser', origin: 'RegisterEntryInput', colClass: 'col-sm-12', required: true},
                { type: 'predefined', predefined: 'section', id: 'section', data: 'loggedUser', colClass: 'col-sm-12', required: true}, //aquest camp provoca al debugger del chrome --> RangeError: Maximum call stack size exceeded 
                { type: 'field', id: 'presentationDate', fieldType: 'date', colClass: 'col-sm-6', required: true, label: 'global.literals.presentationDate', defaultValue: new Date(), remove: vm.entryType == 'OUTPUT', hideExpression: function(){return (vm.entryType == 'OUTPUT');}, showIf: function(){return (vm.entryType != 'OUTPUT');}, isReadOnly:false},
                { type: 'field', id: 'presentationTime', fieldType: 'time', colClass: 'col-sm-6', required: true, label: 'global.literals.time', defaultValue: new Date(), remove: vm.entryType == 'OUTPUT', hideExpression: function(){return (vm.entryType == 'OUTPUT');}, showIf: function(){return (vm.entryType != 'OUTPUT');}, isReadOnly:false },
                { type: 'field', id: 'registerEntryChannel', fieldType: 'select', data: vm.entryType == 'INPUT' ? GlobalDataFactory.registerEntryChannelsInput : GlobalDataFactory.registerEntryChannelsOutput, colClass: getColClass('registerEntryChannel'), required: true, label: 'global.literals.channel', defaultValue: vm.entryType == 'INPUT' ? GlobalDataFactory.registerEntryChannelsInput[0] : GlobalDataFactory.registerEntryChannelsOutput[0],
                	controller: ['$scope', function($scope) {
                		$scope.$on('registerEntryOfficeChanged', function (event, args) {
                			$scope.model.registerEntryChannel = args.item;
                		});
                	}]
                },                
                { type: 'field', id: 'documentDate', fieldType: 'date', colClass: getColClass('documentDate'), required: false, label: 'global.literals.documentDate' },
                { type: 'field', id: 'entryDocumentType', fieldType: 'select', data: vm.entryType == 'INPUT' ? GlobalDataFactory.registerEntryDocumentTypesInput : GlobalDataFactory.registerEntryDocumentTypesOutput, colClass: getColClass('entryDocumentType'), required: false, label: 'global.literals.regType', removeOnEset: true, editPermissions: !vm.diligenceId ? vm.newPerm : undefined },
                { type: 'field', id: 'entryClassification', fieldType: 'select', data: vm.entryType == 'INPUT' ? GlobalDataFactory.registerEntryClassificationsInput : GlobalDataFactory.registerEntryClassificationsOutput, colClass: getColClass('entryClassification'), required: false, label: 'global.literals.classification', removeOnEset: true, editPermissions: !vm.diligenceId ? vm.newPerm : undefined,
                    onSelected: function(item) {
                        $rootScope.$broadcast('registerEntryClassificationChanged', {defaultExtract: item.defaultExtract});
                    }
                },
                { type: 'field', id: 'extract', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.literals.extract',
                    controller: ['$scope', function($scope) {
                        $scope.$on('registerEntryClassificationChanged', function (event, args) {
                            $scope.model.extract = args.defaultExtract;
                        });
                    }]
                },
                { type: 'field', id: 'uniqueWindow', fieldType: 'radio_checkbox', data: [{value: 1, label: $filter('translate')('global.literals.uniqueWindow')}], colClass: 'col-sm-12', required: false, label: '' },
                { type: 'field', id: 'originRegisterDate', fieldType: 'date', colClass: 'col-sm-12', required: false, label: 'global.literals.originRegisterInputDate', hideExpression: hideUniqueWindow, showIf: showIfUniqueWindow },
                { type: 'field', id: 'originEntryNumber', fieldType: 'text', colClass: 'col-sm-12', required: false, label: 'global.literals.originEntryInputNumber', hideExpression: hideUniqueWindow, showIf: showIfUniqueWindow },
                { type: 'field', id: 'originRegisterOrgan', fieldType: 'text', colClass: 'col-sm-12', required: false, label: 'global.literals.originEntryInputOrgan', hideExpression: hideUniqueWindow, showIf: showIfUniqueWindow },
                { type: 'field', id: 'destinyRegisterDate', fieldType: 'date', colClass: 'col-sm-12', required: false, label: 'global.literals.destinyRegisterInputDate', hideExpression: hideUniqueWindow, showIf: showIfUniqueWindow },
                { type: 'field', id: 'destinyEntryNumber', fieldType: 'text', colClass: 'col-sm-12', required: false, label: 'global.literals.destinyEntryInputNumber', hideExpression: hideUniqueWindow, showIf: showIfUniqueWindow },
                { type: 'field', id: 'destinyRegisterOrgan', fieldType: 'text', colClass: 'col-sm-12', required: false, label: 'global.literals.destinyEntryInputOrgan', hideExpression: hideUniqueWindow, showIf: showIfUniqueWindow },
                { type: 'field', id: 'observations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.literals.observations' }
            ]


            if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
                var registerOfficeField = {
                    type: 'field',
                    id: 'registerEntryOffice',
                    fieldType: 'select',
                    data: GlobalDataFactory.registerEntryOffices,
                    colClass: getColClass('registerEntryOffice'),
                    required: true,
                    label: 'global.literals.register_office',
                    editPermissions: 'not_allowed',
                    onSelected: function(item) {                    	
                    	var registerEntryChannel = $linq(vm.newRegisterEntryInputFormFields).where("x => x.id == 'registerEntryChannel'").toArray();
                    	
                		if (vm.entryType == 'INPUT' && item.registerEntryInputChannel) {                			
                			if(registerEntryChannel && registerEntryChannel.length > 0) {
                				$rootScope.$broadcast('registerEntryOfficeChanged', {item: item.registerEntryInputChannel});
                			}
                		} else if (vm.entryType == 'OUTPUT' && item.registerEntryOutputNotificationChannel) {
                			if(registerEntryChannel && registerEntryChannel.length > 0) {
                				$rootScope.$broadcast('registerEntryOfficeChanged', {item: item.registerEntryOutputNotificationChannel});
                			}
                		}
                	}
                }

                var indexRegisterEntryChannel = $linq(vm.newRegisterEntryInputFormFields).indexOf("x => x.id == 'registerEntryChannel'");

                if(indexRegisterEntryChannel == -1) {
                    vm.newRegisterEntryInputFormFields.unshift(registerOfficeField);
                } else {
                    vm.newRegisterEntryInputFormFields.splice(indexRegisterEntryChannel, 0, registerOfficeField);
                }
            }

            if(vm.registerEntry && vm.registerEntry.id && vm.entryType != 'OUTPUT'){
                var efectiveDate = {
                    type: 'field',
                    id: 'effectiveDate',
                    fieldType: 'date',
                    colClass: 'col-sm-6',
                    required: true,
                    label: 'global.literals.effectiveDate',
                    isReadOnly:false
                }
                                
                var efectiveTime = {
                        type: 'field',
                        id: 'effectiveTime',
                        value: vm.registerEntry.effectiveDate,
                        fieldType: 'time',
                        colClass: 'col-sm-6',
                        required: true,
                        label: 'global.literals.time',
                        isReadOnly:false
                    }
                
                var indexPresentationTime = $linq(vm.newRegisterEntryInputFormFields).indexOf("x => x.id == 'presentationTime'");

                if(indexPresentationTime == -1) {
                    vm.newRegisterEntryInputFormFields.splice(0, 0, efectiveDate);
                    vm.newRegisterEntryInputFormFields.splice(1, 0, efectiveTime);
                } else {
                    vm.newRegisterEntryInputFormFields.splice(indexPresentationTime+1, 0, efectiveDate);
                    vm.newRegisterEntryInputFormFields.splice(indexPresentationTime+2, 0, efectiveTime);
                }
            }
            vm.submit = function() {
                var validBox = true;

                this.form.$setSubmitted();

                if($rootScope.app.configuration.reg_init_dossier_diligence_type_required.value && vm.entryType == 'INPUT' && !vm.fromDossier) {
                    if($linq(vm.diligencesBox.content).count("x => x.diligenceType.canStartDossier") == 0) {
                        if(vm.isModal) {
                            DialogsFactory.error($filter('translate')('global.errors.requiredinitdossierdiligence'));
                        } else {
                            vm.alerts.push({msg: 'global.errors.requiredinitdossierdiligence'});
                        }

                        validBox = validBox && false;
                    }
                }

                if($rootScope.app.configuration.reg_thirds_required.value) {
                    if(!vm.thirdBox.content || vm.thirdBox.content.length == 0) {
                        if(vm.isModal) {
                            DialogsFactory.error($filter('translate')(vm.entryType == 'INPUT' ? 'global.errors.requiredthirdsinregisterentryinput' : 'global.errors.requiredthirdsinregisterentryoutput'));
                        } else {
                            vm.alerts.push({msg: vm.entryType == 'INPUT' ? 'global.errors.requiredthirdsinregisterentryinput' : 'global.errors.requiredthirdsinregisterentryoutput'});
                        }

                        validBox = validBox && false;
                    }
                }

                if(this.form.$valid && validBox) {
                    var newRegisterEntry = {
                        registerEntryOffice: this.model.registerEntryOffice ? (this.model.registerEntryOffice.id ? { id: this.model.registerEntryOffice.id } : { id: this.model.registerEntryOffice }) : GlobalDataFactory.defaultRegisterEntryOffice,
                        entryType: vm.entryType,
                        presentationDate: this.model.presentationDate && this.model.presentationTime ?
                            Date.UTC(this.model.presentationDate.getFullYear(), this.model.presentationDate.getMonth(), this.model.presentationDate.getDate(), this.model.presentationTime.getUTCHours(), this.model.presentationTime.getUTCMinutes()) :
                            this.model.presentationDate,
                        registerEntryChannel: isNaN(this.model.registerEntryChannel) ? this.model.registerEntryChannel : { id: this.model.registerEntryChannel },
                        documentDate: this.model.documentDate,
                        entryDocumentType: this.model.entryDocumentType ? { id: this.model.entryDocumentType } : undefined,
                        entryClassification: this.model.entryClassification ? { id: this.model.entryClassification } : undefined,
                        extract: this.model.extract,
                        observations: this.model.observations,
                        profile: this.model.profile ? (this.model.profile.id ? { id: this.model.profile.id } : { id: this.model.profile }) : undefined,
                        section: this.model.section ? (this.model.section.id ? { id: this.model.section.id } : { id: this.model.section }) : undefined,
                        uniqueWindow: this.model.uniqueWindow_1,
                        originRegisterDate: this.model.uniqueWindow_1 ? this.model.originRegisterDate : undefined,
                        originEntryNumber: this.model.uniqueWindow_1 ? this.model.originEntryNumber : undefined,
                        originRegisterOrgan: this.model.uniqueWindow_1 ? this.model.originRegisterOrgan : undefined,
                        destinyRegisterDate: this.model.uniqueWindow_1 ? this.model.destinyRegisterDate : undefined,
                        destinyEntryNumber: this.model.uniqueWindow_1 ? this.model.destinyEntryNumber : undefined,
                        destinyRegisterOrgan: this.model.uniqueWindow_1 ? this.model.destinyRegisterOrgan : undefined,
                        thirds: [],
                        documents: [],
                        entryDiligences: [],
                        relatedDossiers: [],
                        relatedEntries: [],
                        tasks: [],
                        tasksOutput: [],
                        notifications: [],
                        addresses: []
                    }

                    _.forEach(vm.thirdBox.content, function(item) {
                        if(item) {
                            newRegisterEntry.thirds.push({
                                third: {id: item.third.id},
                                interested: item.interested != undefined ? item.interested : false,
                                roleInterested: item.roleInterested ? {id: (item.roleInterested.id ? item.roleInterested.id : item.roleInterested)} : undefined,		
                                representative: item.representative != undefined ? item.representative : false,
                                principal: item.principal,
                                representated: item.representated ? {id: (item.representated.id ? item.representated.id : item.representated)} : undefined,
                                thirdAddress: item.thirdAddress ? {id: (item.thirdAddress.id ? item.thirdAddress.id : item.thirdAddress)} : undefined
                            });
                        }
                    });

                    _.forEach(vm.documentBox.content, function(item) {
                        if(item) {
                            newRegisterEntry.documents.push({
                            	document: {id: item.document.id},
                            	relationType: item.relationType
                            });
                        }
                    })

                    _.forEach(vm.diligencesBox.content, function(item) {
                        if(item) {
                            var newDiligence = {
                                diligenceType: item.diligenceType,
                                diligenceProfile: {id: item.diligenceProfile.id},
                                diligenceState: item.diligenceState,
                                registerEntryDiligenceComments: [],
                                responsibleUser: ((item.responsibleUser && item.responsibleUser.id)? {id:item.responsibleUser.id} :  undefined)
                            };

                            _.forEach(item.registerEntryDiligenceComments, function (com) {
                                newDiligence.registerEntryDiligenceComments.push({
                                    createdUser: {id: $rootScope.LoggedUser.id},
                                    comments: com.comments
                                });
                            });

                            newRegisterEntry.entryDiligences.push(newDiligence);
                        }
                    });

                    _.forEach(vm.dossierBox.content, function(item) {
                        if(item) {
                            newRegisterEntry.relatedDossiers.push({id: item.id});
                        }
                    });

                    _.forEach(vm.registerEntryBox.content, function(item) {
                        if(item) {
                            newRegisterEntry.relatedEntries.push(({id: item.id}));
                        }
                    });

                    _.forEach(vm.taskBox.content, function (item) {
                        if(item) {
                            if (vm.entryType == 'INPUT') {
                                newRegisterEntry.tasks.push({id: item.id});
                            } else {
                                newRegisterEntry.tasksOutput.push({id: item.id});
                            }
                        }
                    });

                    _.forEach(vm.notificationsBox.content, function (item) {
                        if(item) {
                        	//Si estoy en tramites
                        	if($state.current.name.startsWith('annexa.tram') && vm.isModal && TramFactory.Dossier && TramFactory.Dossier.id){
                        		item.dossier = {id: TramFactory.Dossier.id};
                        	}
                            newRegisterEntry.notifications.push(item);
                        }
                    });
                    
                    _.forEach(vm.addressBox.content, function (item) {
                        if(item) {
                        	newRegisterEntry.addresses.push({
                            	address: {id: item.address.id}
                            });
                        }
                    });

                    RegFactory.createRegisterEntry(newRegisterEntry)
                        .then(function(data) {
                            if(vm.isModal) {
                                $rootScope.$broadcast('createdRegisterEntry', { id: 'modalNewRegisterEntry', data: data, addNotifications:($state.current.name.startsWith('annexa.tram') && vm.isModal && TramFactory.Dossier && TramFactory.Dossier.id) });
                                $rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalNewRegisterEntry', data: data });
                            }
                            if(vm.redirectToList) {
                                $state.transitionTo(vm.entryType == 'INPUT' ? 'annexa.reg.input.entries' : 'annexa.reg.output.entries');
                            }
                        }).catch(function (error) {
                            $rootScope.$broadcast('dummy');
                            if(vm.isModal) {
                                DialogsFactory.error(ErrorFactory.getErrorMessage('register', 'new', error));
                            } else {
                                vm.alerts.push({msg: ErrorFactory.getErrorMessage('register', 'new', error)});
                            }
                    })
                }
            }

            vm.alerts = [];

            //endregion

            //region Thrids Box

            $scope.$on('annexaBoxThirdsDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-reg' && vm.isEdit) {
                    RegFactory.deleteRegisterEntryThird(vm.registerEntry.id, args.removedIds)
                        .then(function(data) {
                            if(data.data != -1) {
                                var indexNewPrincipal = $linq(vm.thirdBox.content).indexOf("x => x.id == " + data.data);

                                if(indexNewPrincipal != -1) {
                                    vm.thirdBox.content[indexNewPrincipal].principal = true;
                                }
                            }
                        }).catch(function(error) {
                            console.log(error);
                    })
                }
            });

            $scope.$on('annexaBoxThirdsSetPrincipal', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-reg' && vm.isEdit) {
                    RegFactory.setPrincipalRegisterEntryThird(vm.registerEntry.id, args.id)
                        .then(function() {
                            //Empty
                        }).catch(function(error) {
                        console.log(error);
                    })
                }
            });

            var addThirdSave = function(newThird) {
                var newRegisterEntryThird = {
                    registerEntry: { id: vm.registerEntry.id },
                    third: { id: newThird.third.id },
                    interested: newThird.interested == undefined ? false : newThird.interested,
                    roleInterested: newThird.roleInterested ? { id: (typeof newThird.roleInterested == 'number' ? newThird.roleInterested : newThird.roleInterested.id) } : undefined,  		
                    representative: newThird.representative == undefined ? false : newThird.representative,
                    representated: newThird.representated ? { id: (typeof newThird.representated == 'number' ? newThird.representated : newThird.representated.id) } : undefined,
                    principal: newThird.principal == undefined ? false : newThird.principal,
                    thirdAddress: newThird.thirdAddress ? {id: newThird.thirdAddress} : undefined
                }

                RegFactory.newRegisterEntryThird(vm.registerEntry.id, newRegisterEntryThird)
                    .then(function(data) {
                    	if (vm.registerEntry) {
                    		newThird.id = data.id;
                    		if (vm.registerEntry.thirds == null || vm.registerEntry.thirds == undefined) {       
                    			vm.registerEntry.thirds = [];
                    		}
                    		vm.registerEntry.thirds.push(newThird);
                    	}
                    }).catch(function (error) {
                        console.error(error)
                });
            }

            //endregion

            //region Documents Box

            $scope.$on('annexaBoxDocumentsDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-reg' && vm.isEdit) {
                    RegFactory.deleteRegisterEntryDocument(vm.registerEntry.id, args.removedId)
                        .then(function () {
                            //Empty
                        }).catch(function(error) {
                            console.error(error);
                    });
                }
            });

            var addDocumentSave = function(newRegisterEntryDocument) {
                $rootScope.loading(true);
                
                RegFactory.newRegisterEntryDocument(vm.registerEntry.id, newRegisterEntryDocument)
                    .then(function(data) {
                    	vm.documentBox.content.length =0;
                    	_.forEach(data.documents, function(doc){
                    		 vm.documentBox.content.push(doc);
                    	});
                    	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.documentBox.content, relationType: 'DOCUMENT'});
                        $rootScope.loading(false);
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        console.error(error);
                        if (error.data && error.data.message) {
                        	vm.alerts.push({ msg: error.data.message });
                        } else {
                        	vm.alerts.push({ msg: 'global.errors.errorCreateDocument' });
                        }
                });
            }

            //endregion

            //region Diligences Box

            $scope.$on('annexaBoxDiligencesDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-reg' && vm.isEdit) {
                    RegFactory.deleteRegisterEntryDiligence(vm.registerEntry.id, args.removedId)
                        .then(function () {
                            //Empty
                        }).catch(function(error) {
                        console.error(error);
                    });
                }
            });

            var addDiligence = function(newDiligence) {
                RegFactory.newRegisterEntryDiligence(vm.registerEntry.id, newDiligence)
                    .then(function(data) {
                        vm.diligencesBox.content.push(data);
                    }).catch(function(error) {
                    	vm.alerts.push({msg: ErrorFactory.getErrorMessage('register', 'new', error)});
                });
            }

            //endregion

            //region Dossier Box

            $scope.$on('annexaBoxDossiersDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-reg' && vm.isEdit) {
                    RegFactory.deleteRegisterEntryDossier(vm.registerEntry.id, args.removedId)
                        .then(function () {
                            //Empty
                        }).catch(function(error) {
                        console.error(error);
                    });
                }
            });

            var addDossierSave = function(newDossier) {
                RegFactory.newRegisterEntryDossier(vm.registerEntry.id,  $linq(newDossier).select("x => x.id").toArray())
                    .then(function(data) {
                        //Empty
                    }).catch(function(error) {
                        console.error(error);
                });
            }

            //endregion

            //region RegisterEntry Box

            var addRegisterEntrySave = function(newRegisterEntry) {
                RegFactory.newRegisterEntryRelatedRegisterEntry(vm.registerEntry.id, $linq(newRegisterEntry).select("x => x.id").toArray())
                    .then(function(data) {
                        //Empty
                    }).catch(function(error) {
                        console.error(error);
                });
            }

            $scope.$on('annexaBoxRegisterEntriesDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-reg' && vm.isEdit) {
                    RegFactory.deleteRegisterEntryRelatedRegisterEntry(vm.registerEntry.id, args.removedId)
                        .then(function () {
                            //Empty
                        }).catch(function(error) {
                            console.error(error);
                    });
                }
            });

            //endregion

            //region Notifications Box

            var addNotificationSave = function (notifications) {
                NotificationFactory.createMultipleNotifications(notifications)
                    .then(function(data) {
                        _.forEach(data, function (item) {
                            vm.notificationsBox.content.push(item);
                        });
                    }).catch(function (error) {
                    	if(error && error.data && error.data.message == 'No notification type'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.noNotificationType'));
                    	}else if(error && error.data && error.data.message == 'channelNoValidSendNotifications'){
                    		DialogsFactory.error($filter('translate')('global.errors.channelNoValidSendNotifications'));
                    	}else{
                    		DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
                    	}
                        console.error(error);
                });
            }
            
            var updateDouments = function(registerEntry) {
                if(registerEntry.documents){
                	vm.documentBox.content = registerEntry.documents;
                	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.documentBox.content, relationType: 'DOCUMENT'});
                    $rootScope.loading(false);
                }
            }

            //endregion

            //region Task Box

            var addTaskSave = function (newTask) {
                RegFactory.newRegisterEntryTask(vm.registerEntry.id, $linq(newTask).select("x => x.id").toArray())
                    .then(function (data) {
                        //Empty
                    }).catch(function(error) {
                        console.error(error);
                });
            }

            
            var addAddressSave = function (newAddress) {
                $rootScope.loading(true);
                    
            	RegFactory.newRegisterEntryAddress(vm.registerEntry.id, newAddress)
                    .then(function (data) {
                    	vm.addressBox.content.length =0;
                    	_.forEach(data.addresses, function(address){
                    		 vm.addressBox.content.push(address);
                    	});
                        $rootScope.loading(false);
                    }).catch(function(error) {
                    	$rootScope.loading(false);
                        console.error(error);
                        if (error.data && error.data.message) {
                        	vm.alerts.push({ msg: error.data.message });
                        } else {
                        	vm.alerts.push({ msg: 'global.errors.unknownNew' });
                        }
                });
            }

            $scope.$on('annexaBoxTasksDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-reg' && vm.isEdit) {
                    RegFactory.deleteRegisterEntryTask(vm.registerEntry.id, args.removedId)
                        .then(function () {
                            //Empty
                        }).catch(function(error) {
                            console.error(error);
                    });
                }
            });

            
            $scope.$on('annexaBoxAddressDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-reg' && vm.isEdit) {
                    RegFactory.deleteRegisterEntryAddress(vm.registerEntry.id, args.removedId)
                        .then(function () {
                            //Empty
                        }).catch(function(error) {
                            console.error(error);
                    });
                }
            });

            //endregion

            //region General Box

            var getEditPermissions = function(type) {
                var canEdit = false;

                if(!vm.diligenceId) {
                    if (type == 'diligences' || type == 'registerEntries') {
                        canEdit = true;
                        
                    } else {

                        var editDays = 0;

                        if (vm.isEdit) {
                            editDays = vm.registerEntry.registerEntryOffice.registerEntryEditDays;
                        }

                        if (editDays == 0) {
                            canEdit = true;
                        } else {
                            var daysFromEdit = HelperService.diffDateDays(vm.registerEntry.createdDate, new Date());

                            if (daysFromEdit <= editDays) {
                                canEdit = true;
                            }
                        }
                    }
                } else if(vm.diligenceId && type == 'tasks') {
                    canEdit =
                        (vm.registerEntryDiligence.diligenceType.type == 'ACCEPTANCE' && vm.registerEntryDiligence.diligenceState == 'ACCEPT') ||
                        (vm.registerEntryDiligence.diligenceType.type == 'ACCESS' && vm.registerEntryDiligence.diligenceState != 'FINISHED' && vm.registerEntryDiligence.diligenceState != 'REJECT');
                }
                
                var permissions = [];
                if(!canEdit) {
                	return type ? {}:[];
                } else {                    
                    if(AnnexaPermissionsFactory.havePermission(vm.editPerm)) {
                        permissions.push(vm.editPerm);
                    }

                    if(AnnexaPermissionsFactory.havePermission(vm.newPerm)) {
                        permissions.push(vm.newPerm);
                    }                    
                }
                
                if (permissions.length > 0) {                	               
	                switch (type) {
	                    case 'thirds':
	                        return (vm.isEdit && canEdit) || !vm.isEdit ? { new: 'new_third', view: vm.newPerm } : {};
	                        break;
	                    case 'documents':
	                        return (vm.isEdit && canEdit) || !vm.isEdit ? { new: 'new_document', view: vm.newPerm } : {};
	                        break;
	                    case 'diligences':
	                        return !vm.diligenceId ? { new: 'new_input_entry_register' } : {};
	                        break;
	                    case 'dossiers':
	                        return (vm.isEdit && canEdit) || !vm.isEdit ? { view: vm.newPerm } : {};
	                        break;
	                    case 'registerEntries':
	                        return (vm.isEdit && canEdit) || !vm.isEdit ? { view: vm.newPerm } : {};
	                        break;
	                    case 'notifications':
	                        return (vm.isEdit && canEdit) || !vm.isEdit ? { new: vm.newPerm } : {};
	                        break;
	                    case 'tasks':
	                        return (vm.isEdit && canEdit) || !vm.isEdit ? { new: vm.newPerm, view: vm.newPerm } : {};
	                        break;
	                    case 'address':
	                        return (vm.isEdit && canEdit) || !vm.isEdit ? { new: vm.newPerm, view: vm.newPerm } : {};
	                        break;
	                    default:     
	                    	return permissions;
	                        break;
	                }
                } else {
                	return type ? {}:[];
                }
                
            }

            //endregion

            this.$onInit = function() {

                vm.newPerm = vm.entryType == 'INPUT' ? 'new_input_entry_register' : 'new_output_entry_register';
                vm.editPerm = vm.entryType == 'INPUT' ? 'edit_input_entry_register' : 'edit_output_entry_register';
                vm.module = vm.entryType == 'INPUT' ? 'REGENTRADA' : 'REGSALIDA';

                vm.registerEntryDiligence = undefined;
                vm.registerEntryDiligenceState = undefined;

                if(vm.registerEntry && vm.diligenceId) {
                    var indexDiligence = $linq(vm.registerEntry.entryDiligences).indexOf("x => x.id == " + vm.diligenceId);

                    if(indexDiligence != -1) {
                        vm.registerEntryDiligence = vm.registerEntry.entryDiligences[indexDiligence];
                        vm.registerEntryDiligenceState = $filter('translate')('global.reg.diligenceState.' + vm.registerEntryDiligence.diligenceState);
                    }
                }

                vm.updateOpenProfileStatus = function(isOpen, profile){
                	if(isOpen && profile && profile.id && (!profile.userProfiles || profile.userProfiles.length == 0)){
                		 RestService.findOne('Profile', profile.id, 'exportProfile').then(function (data) {
                             var pAux = JSOG.decode(data.data);
                             if(pAux.userProfiles && pAux.userProfiles.length > 0){
                            	 profile.userProfiles = pAux.userProfiles;
                             }
                         });
                	}
                }
                //region Init Boxs

                vm.editPermissions = getEditPermissions();

                var thirdIdentificationDocumentTypesFilter = undefined;

                if($rootScope.app.configuration.reg_thirds_permited_identification_document_types.value) {
                    thirdIdentificationDocumentTypesFilter = {
                        thirdIdentificationDocumentTypes: $rootScope.app.configuration.reg_thirds_permited_identification_document_types.value
                    }
                }

                vm.thirdBox = {
                    boxTitle: vm.entryType == 'INPUT' ? 'global.literals.origin' : 'global.literals.destiny',
                    permissions: getEditPermissions('thirds'),
                    search: {
                        placeholder: 'global.literals.placeholder_search_receiver',
                        advanced: true,
                        saveSearch: vm.isEdit ? addThirdSave : undefined,
                        additionalFilter: thirdIdentificationDocumentTypesFilter,
                    },
                    content: vm.registerEntry ? vm.registerEntry.thirds : (vm.preloadBoxes && vm.preloadBoxes.thirds ? vm.preloadBoxes.thirds : []),
                    new: {},
                    config: {viewConsentAll:true},
                    origin: 'annexa-box-object-reg'
                }

                $rootScope.$broadcast('dummy');

                vm.documentBox = {
                    permissions: getEditPermissions('documents'),
                    search: {
                        placeholder: 'global.literals.placeholser_search_documents',
                        advanced: true,
                        saveSearch: vm.isEdit ? addDocumentSave : undefined,
                        addAfter: true,
                        additionalFilter: { exceptState: 'NEW', documentBoxNormalSearch: true, includeRegisterEntry: true }
                    },
                    content: vm.registerEntry ? vm.registerEntry.documents : (vm.preloadBoxes && vm.preloadBoxes.documents ? vm.preloadBoxes.documents : []),
                    new: { multiple: { scanner: true } },
                    config: { documentTitle: undefined, module: vm.module, thirdBox: vm.thirdBox },
                    origin: 'annexa-box-object-reg'
                }

                vm.diligencesBox = {
                    permissions: getEditPermissions('diligences'),
                    search: { saveSearch: vm.isEdit ? addDiligence : undefined, addAfter: true },
                    content:  vm.registerEntry ?  vm.registerEntry.entryDiligences : [],
                    new: {},
                    config: {},
                    origin: 'annexa-box-object-reg'
                }

                vm.dossierBox = {
                    permissions: getEditPermissions('dossiers'),
                    search: { placeholder: 'global.literals.placeholder_search_dossiers', advanced: true, saveSearch: vm.isEdit ? addDossierSave : undefined },
                    content:  vm.registerEntry ?  vm.registerEntry.relatedDossiers : (vm.preloadBoxes && vm.preloadBoxes.dossiers ? vm.preloadBoxes.dossiers : []),
                    config: {},
                    origin: 'annexa-box-object-reg'
                }

                vm.registerEntryBox = {
                    permissions: getEditPermissions('registerEntries'),
                    search: { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: vm.isEdit ? addRegisterEntrySave : undefined },
                    content:  vm.registerEntry ?  vm.registerEntry.relatedEntries : [],
                    config: {},
                    origin: 'annexa-box-object-reg'
                }

                vm.notificationsBox = {
                    permissions: getEditPermissions('notifications'),
                    search: { saveSearch: vm.isEdit ? addNotificationSave : undefined },
                    content:  vm.registerEntry ?  vm.registerEntry.notifications : [],
                    new: {},
                    config: { thirdBox: vm.thirdBox, documentBox: vm.documentBox, registerEntry: vm.registerEntry, updateDocumentsFunction: updateDouments },
                    origin: 'annexa-box-object-reg',
                    isModal:vm.isModal
                }

                vm.taskBox = {
                    permissions: getEditPermissions('tasks'),
                    search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: vm.isEdit ? addTaskSave : undefined },
                    content:  vm.registerEntry ?  (vm.entryType == 'INPUT' ? vm.registerEntry.tasks : vm.registerEntry.tasksOutput) : [],
                    new: {},
                    config: {},
                    origin: 'annexa-box-object-reg'
                }
                
                vm.addressBox = {
                    permissions: getEditPermissions('address'),
                    search: { placeholder: 'global.literals.placeholderSearchAddressBox', advanced: false, saveSearch: vm.isEdit ? addAddressSave : undefined },
                    content:  vm.registerEntry ?  vm.registerEntry.addresses : [],
                    new: {},
                    config: {},
                    origin: 'annexa-box-object-reg'
                }
                if(!TerritoryFactory.canCreateAddress()) {
                	vm.addressBox.new = undefined;
                }

                //endregion
            }

            //region RegisterEntry Methods

            vm.saveRegistry = function() {
                $rootScope.$broadcast('submitForm', { id: 'new-registerentry' });
            }

            vm.cancelRegistry = function() {
                $state.transitionTo(vm.entryType == 'INPUT' ? 'annexa.reg.input.entries' : 'annexa.reg.output.entries');
            }

            vm.updateRegisterEntry = function(val, prop) {            	
            	if (prop == 'profile') {
            		vm.updateRegisterEntryProfile = true;
            		vm.oldRegisterEntryProfile = vm.registerEntry.profile;
            	} else {
            		vm.updateRegisterEntryProfile = false;
            	}
            	
                if(prop == 'managed') {
                    vm.registerEntry[prop] = val.id;
                    if(val.id) {
                        vm.registerEntry.managedUser = { id: $rootScope.LoggedUser.id };
                        vm.registerEntry.managedDate = new Date();
                    } else {
                        vm.registerEntry.managedUser = undefined;
                        vm.registerEntry.managedDate = undefined;
                    }
                } else if(prop == 'pending') {
                    vm.registerEntry[prop] = val.id;
                } else {
                    vm.registerEntry[prop] = val;
                }

                RestService.update('./api/reg/' + vm.registerEntry.id, vm.registerEntry)
                    .then(function (data) {
                        
                    	if (vm.updateRegisterEntryProfile) {
                    		if ($rootScope.app.configuration.automatic_change_documents_profile_when_change_registry_profile.value) {
                    			RestService.updateRegisterEntryDocumentsProfile(data.id, vm.oldRegisterEntryProfile.id, data.profile.id).then(function (data3) {
                    				var a = 0;
                    			}).catch(function (error) {
                    				DialogsFactory.error($filter('translate')('global.documents.errorAutomaticChangeDocumentsProfileWhenChangeRegistryProfile'), $filter('translate')('DIALOGS_ERROR_MSG'));
            					});
                    		} else {
                    			DialogsFactory.confirm('global.documents.confirmAutomaticChangeDocumentsProfileWhenChangeRegistryProfileTitle', 'global.documents.confirmAutomaticChangeDocumentsProfileWhenChangeRegistryProfile')
                    				.then(function (data2) {
                    					RestService.updateRegisterEntryDocumentsProfile(data.id, vm.oldRegisterEntryProfile.id, data.profile.id).then(function (data3) {
                    						var a = 0;
                    					}).catch(function (error) {
                    						DialogsFactory.error($filter('translate')('global.documents.errorAutomaticChangeDocumentsProfileWhenChangeRegistryProfile'), $filter('translate')('DIALOGS_ERROR_MSG'));
                    					});                    			
                    			});
                    		}
                    		
                        }
                    	
                    }).catch(function (error) {
                        console.error(error);
                });
            }

            vm.createSuportingDocument = function () {
                $rootScope.loading(true);

                RegFactory.createSupportingDocument(vm.registerEntry.id, vm.entryType, 'DOCUMENT')
                    .then(function(data) {
                        vm.documentBox.content.push(data);
                        $rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.documentBox.content, relationType: 'INTERNAL_DOCUMENT'});
                        $rootScope.loading(false);
                    }).catch(function(error) {
                        $rootScope.loading(false);

                        DialogsFactory.error(ErrorFactory.getErrorMessage('register', 'supportingdocument', error && error.data ? error.data : ''));
                });
            }
            
            vm.generateDocumentSicres = function () {            	            	
            	RegFactory.getFieldsSicresForm()
            	.then(function(data) {  
            		
            		var submitSendTo = function() {
                        var self = this;
                        this.form.$setSubmitted();
                        sicresModal.alerts.length = 0;
                        if(this.fieldsDefinition[0].data.model) {
                            var modelSicres = this.fieldsDefinition[0].data.model;
                            
                            RegFactory.generateDocumentSicres(RegFactory.regEntry.id, angular.toJson(modelSicres['document_sicres_button']))
                            .then(function(data) {
                            	vm.documentBox.content.push(data);
                            	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.documentBox.content, relationType: 'INTERNAL_DOCUMENT'});
                                $rootScope.loading(false);
                                sicresModal.close();
                            }).catch(function(error) {
                                $rootScope.loading(false);
                                console.log(error);
                                sicresModal.alerts.push({ msg: 'global.errors.unknownNew'});
                            });
                            
                        }else{
                            sicresModal.close();
                        }
                    }
            		
            		var button = angular.fromJson('{"id":"document_sicres_button", "name":"UI Button for generate SICRES Document XML", "params":"", "returnType":"FILE_CONTENTS_FOR_DOWNLOAD"}');
            		button.params = angular.fromJson(data);
            		
                	var sicresModal = {
                		title: 'global.reg.literals.generateDocumentSicres',
                        size: '',
                        fieldsDefinition: [
                            { type: 'field', id: 'annexaRegisterDocument', fieldType: 'component', componentType: 'annexa-register-document',
                                data: {
                                    registerEntry: RegFactory.regEntry,
                                    plugin: button,
                                    model: {}
                                }
                            }
                        ],
                        alerts: [],
                        submitModal: submitSendTo
                    }
                	AnnexaFormlyFactory.showAnnexaFormModal('modalSICRES', sicresModal);
                	
                }).catch(function(error) {                    
                    console.log(error);                    
                });            	            	
            }

            $scope.$on('AnnexaProfileEditUpdated', function (event, args) {
                if(args.profile) {
                    vm.updateRegisterEntry(args.profile, 'profile');
                }
            });

            vm.auditRegisterEntry = function () {
                if (vm.registerEntry && vm.registerEntry.id) {
                    window.open($state.href('annexa.admin.audit_register_entry', {"objectId": vm.registerEntry.id}), '_blank');
                }
            };
            
            vm.duplicateRegister = function () {
            	var submitDuplicateRegister = function () {
                    var self = this;
                    this.form.$setSubmitted();

                    if(this.form.$valid) {
                    	var selectedRegisterOffice = $linq(GlobalDataFactory.registerEntryOffices).singleOrDefault(undefined, "x => x.id == " + this.model.regOffice);
                    	var newRegisterEntry = {
                    		registerEntryOffice: selectedRegisterOffice,
                    		profile: this.model.profile ? (this.model.profile.id ? { id: this.model.profile.id } : { id: this.model.profile }) : undefined,
                    		section: this.model.section ? (this.model.section.id ? { id: this.model.section.id } : { id: this.model.section }) : undefined
                    	}
                        /*var selectedRegisterOffice = $linq(GlobalDataFactory.registerEntryOffices).singleOrDefault(undefined, "x => x.id == " + this.model.regOffice);
                        var profile = this.model.profile ? (this.model.profile.id ? { id: this.model.profile.id } : { id: this.model.profile }) : undefined;
                        var section = this.model.section ? (this.model.section.id ? { id: this.model.section.id } : { id: this.model.section }) : undefined;*/

                        if(selectedRegisterOffice) {
                        	$rootScope.loading(true);

                            RegFactory.duplicateRegisterEntryDiligence(vm.registerEntry.id, newRegisterEntry)
                                .then(function (data) {
                                	$rootScope.loading(false);
                                	if(data && data != "") {
                                        $rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalDuplicateRegister' });
                                        DialogsFactory.notify('global.literals.duplicateRegOk');
                                	}
                                }).catch(function (error) {
                                    $rootScope.loading(false);
                                    console.error(error);
                            })
                        } else {
                            newModal.alerts.push({ msg: 'global.errors.diligenceTypeNotFound'});
                        }
                    }
                };
                
                var registerEntryOffices = [];
                _.forEach(GlobalDataFactory.registerEntryOffices, function(regEntryOffice){
                	if(vm.registerEntry.registerEntryOffice.id != regEntryOffice.id){
                    	registerEntryOffices.push(regEntryOffice);
                	}
	           	});
                
                var newModal = RegFactory.duplicateRegisterModal(submitDuplicateRegister, undefined, registerEntryOffices);

                AnnexaFormlyFactory.showAnnexaFormModal('modalDuplicateRegister', newModal);
            };

            //endregion

            //region Diligence Methods

            vm.diligenceResponsibleUser = function() {
                $rootScope.loading(true);

                RegFactory.responsibleUser(vm.registerEntryDiligence)
                    .then(function (data) {
                        vm.registerEntryDiligence.responsibleUser = vm.registerEntryDiligence.responsibleUser ? undefined : $rootScope.LoggedUser;
                        $rootScope.loading(false);
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        console.error(error);
                })
            }

            var updateDiligenceState = function(state, comment, modal) {
                $rootScope.loading(true);

                RegFactory.updateRegisterEntryDiligenceState(vm.registerEntryDiligence, state, comment)
                    .then(function(data) {
                        $rootScope.loading(false);
                        vm.registerEntryDiligence.diligenceState = state;
                        vm.registerEntryDiligenceState = $filter('translate')('global.reg.diligenceState.' + vm.registerEntryDiligence.diligenceState);

                        $rootScope.$broadcast('registerEntryDiligenceStateUpdated');

                        if(modal) {
                            modal.close();
                        }

                        if(state == 'REJECT') {
                            $state.go('annexa.reg.input.diligences');
                        }

                        if(state == 'ACCEPT') {
                            vm.taskBox.permissions = getEditPermissions('tasks');
                        }
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        console.error(error);
                        if(modal) {
                            modal.alerts.push({ msg: ErrorFactory.getErrorMessage('registerDiligence', 'updateState', error) });
                        }
                });
            }

            vm.acceptDiligence = function() {
                updateDiligenceState('ACCEPT');
            }

            vm.rejectDiligence = function() {
                var rejectReasonModal = {
                    title: 'global.literals.rejectReason',
                    size: '',
                    fieldsDefinition: [
                        { type: 'field', id: 'reason', fieldType: 'textarea', colClass: 'col-sm-12', required: true, label: 'global.literals.reason', maxLength: 1024 },
                    ],
                    alerts: [],
                    submit: function() {
                    }
                }

                var submitRejectReasonModal = function() {
                    this.form.$setSubmitted();

                    if(this.form.$valid) {
                        updateDiligenceState(
                            'REJECT',
                            '#' + Language.getActiveColumn() + '#' + this.model.reason + "@@" + $filter('translate')('global.literals.registerEntryDiligenceComment', { profile: vm.registerEntryDiligence.diligenceProfile[Language.getActiveColumn()] }),
                            rejectReasonModal
                        );
                    }
                }

                AnnexaFormlyFactory.showAnnexaFormModal('modalRejectReasonModal', rejectReasonModal, submitRejectReasonModal);
            }

            vm.finishDiligence = function () {
                updateDiligenceState('FINISHED');
            }

            vm.readDiligence = function() {
                updateDiligenceState('READ');
            }

            vm.sendToDiligence = function () {
                RegFactory.sendTodiligenceModalOpen(vm.registerEntryDiligence, vm.registerEntry, 'annexa.reg.input.diligences');
            }

            vm.auditDiligence = function () {
                if (vm.diligenceId) {
                    window.open($state.href('annexa.admin.audit_diligence', {"objectId": vm.diligenceId }), '_blank');
                }
            };

            //endregion

            //region Dossier Methods


            var getAllowedFamilyProcedures = function(registerEntryClassification, profile, initDossierModal) {
                var procedureHaveStartProfile = function (profile, procedure) {
                    return $linq(procedure.procedureStartProfiles).count("x => x.profile.id == " + profile.id) > 0;
                }

                var getProceduresByProfile = function(profile, procedures) {
                    var retProcedures = [];

                    _.forEach(procedures, function(item) {
                        if(procedureHaveStartProfile(profile, item)) {
                            retProcedures.push(item);
                        }
                    });

                    return retProcedures;
                }

                var addParentFamily = function (parent, listFamilyProcedures) {
                    if($linq(listFamilyProcedures).count("x => x.id == " + parent.id) == 0) {
                        if(parent.parent) {
                            listFamilyProcedures = addParentFamily(parent.parent, listFamilyProcedures);
                        }

                        listFamilyProcedures.push(parent);
                    }

                    return listFamilyProcedures;
                }

                var familyProcedures = RegFactory.familyProcedures;
                var retFamilyProcedures = [];

                if(registerEntryClassification && registerEntryClassification.procedures && registerEntryClassification.procedures.length > 0) {
                    familyProcedures = [];

                    _.forEach(registerEntryClassification.procedures, function(item) {
                        if(item.family && $linq(familyProcedures).count("x => x.id == " + item.family.id) == 0) {
                            var family = $linq(RegFactory.familyProcedures).singleOrDefault(undefined, "x => x.id == " + item.family.id);

                            if(family) {
                                familyProcedures.push(family);
                            }
                        }
                    });
                }

                if(familyProcedures && familyProcedures.length > 0) {
                    _.forEach(familyProcedures, function (item) {
                        var procedures = getProceduresByProfile(profile, item.procedures);

                        if(procedures && procedures.length > 0) {
                            if($linq(retFamilyProcedures).count("x => x.id == " + item.id) == 0) {
                                if (item.parent) {
                                    retFamilyProcedures = addParentFamily(item.parent, retFamilyProcedures);
                                }

                                retFamilyProcedures.push(item);
                            }
                        }
                    })
                }

                return retFamilyProcedures;
            }
            
            var getAllFamiliesFromParent = function (familyTree, parentId) {
            	var lstFamilyId = [];
            	
            	if (familyTree && parentId){
            		if (familyTree[parentId]) {            			            		
	            		_.forEach(familyTree[parentId], function(familyChild) {
	            			var listAux = getAllFamiliesFromParent(familyTree, familyChild.id);
	            			lstFamilyId = lstFamilyId.concat(listAux);
	            		});	            		
            		}
            		if (!_.contains(lstFamilyId, parentId)) {
            			lstFamilyId.push(parentId);
            		}
            	}
            	
            	return lstFamilyId;
            }

            // iniciar un expediente
            vm.initDossier = function () {
            	RegFactory.getFamilyProcedures().then(function(data) {
	                var initDossierModal = {
	                    title: 'global.literals.initExp',
	                    size: '',
	                    icon: 'fa fa-file-text-o',
	                    fieldsDefinition: [
	                        { type: 'field', id: 'familyProcedure', fieldType: 'select_tree', data: CommonService.getTreeData(getAllowedFamilyProcedures(vm.registerEntry.entryClassification, vm.registerEntryDiligence.diligenceProfile, initDossierModal), Language.getActiveColumn()), required: true, label: 'global.literals.family', model: {} },
	                        { type: 'field', id: 'procedure', fieldType: 'select', data: [], required: true, label: 'global.literals.procedure',
	                            watchers: {
	                                type: '$watchCollection',
	                                expression: 'model.familyProcedure',
	                                listener: function(field, _new) {
	                                    if(_new && _new.$selected && _new.$selected.id) {
	
	                                        var procedureAll = [];
	                                        var lstFamilyId = [];
	                                        var familyTree = angular.element('#modalInitDossier');
	                                        
	                                        var procHaveStartProfile = function (profile, procedure) {
	                                            return $linq(procedure.procedureStartProfiles).count("x => x.profile.id == " + profile.id) > 0;
	                                        }
	                                        
	                                        if (familyTree && familyTree.scope() && familyTree.scope().modalObj && familyTree.scope().modalObj.fieldsDefinition 
	                                        		&& familyTree.scope().modalObj.fieldsDefinition[0] && familyTree.scope().modalObj.fieldsDefinition[0].data) {
	                                        	lstFamilyId = getAllFamiliesFromParent(familyTree.scope().modalObj.fieldsDefinition[0].data,_new.$selected.id);
	                                        } else {
	                                        	lstFamilyId.push(_new.$selected.id);
	                                        }
	
	                                        if( (vm.registerEntry.entryClassification && (!vm.registerEntry.entryClassification.procedures || vm.registerEntry.entryClassification.procedures.length == 0)) || !vm.registerEntry.entryClassification ){
	                                            procedureAll = $linq(GlobalDataFactory.procedures).where(function(x){
	                                            	if (x.active && x.family && x.family.id && _.contains(lstFamilyId, x.family.id)) {
	                                            		return true;
	                                            	} else {
	                                            		return false;
	                                            	}
	                                            }).toArray();
	                                        }
	
	                                        else if( vm.registerEntry.entryClassification && vm.registerEntry.entryClassification.procedures ){
	                                            procedureAll = $linq(vm.registerEntry.entryClassification.procedures).where(function(x){
	                                            	if (x.active && x.family && x.family.id && _.contains(lstFamilyId, x.family.id)) {
	                                            		return true;
	                                            	} else {
	                                            		return false;
	                                            	}
	                                            }).toArray();
	                                        }
	
	                                        if(procedureAll && procedureAll.length > 0){
	                                        	var procedureAllComplete = [];
	                                        	_.forEach(procedureAll, function(proc){
	                                        		if(proc.procedureStartProfiles && proc.procedureStartProfiles.length > 0){
		                                        		if(procHaveStartProfile(vm.registerEntryDiligence.diligenceProfile, proc)){
		                                        			procedureAllComplete.push(proc);
		                                        		}
	                                        		}else{
	                                        			var proceduresAux = $linq(RegFactory.familyProcedures).where(function(x){if (x.id && _.contains(lstFamilyId, x.id)) {return true;} else {return false;}}).select("x => x.procedures").toArray();
	                                                    if(proceduresAux && proceduresAux.length > 0){
	                                                        var proceduresAllAux = [];        
		                                       				_.forEach(proceduresAux, function(procAux){
		                                       					if(procAux && procAux.length > 0){
		                                       				    	proceduresAllAux = $linq(proceduresAllAux).concat(procAux).toArray();
		                                       				    }
		                                       				 });
		                                       				 if(proceduresAllAux && proceduresAllAux.length > 0){
		                                       					 var procOk = $linq(proceduresAllAux).firstOrDefault(undefined, "x => x.id == "+proc.id);
		                                       					 if(procOk){
		                                       						if(procHaveStartProfile(vm.registerEntryDiligence.diligenceProfile, procOk)){
		         	                                        			procedureAllComplete.push(angular.copy(procOk));
		         	                                        		}	                                       						 
		                                       					 }
		                                       				 }
		                                       			 }
	                                        		}
	                                        	});
	                                        	
	                                        	//TODO quitar if, por ahora en modo ESET solo se han de poder iniciar procedimientos NO guiados
	                                            /*if ($rootScope.esetMode) {
	                                            	//Solo procedimientos NO guiados
	                                            	procedureAllComplete = $linq(procedureAllComplete).where("x => x.guided == false").toArray();
	                                            }*/
	                                        	
	                                        	field.templateOptions.options = procedureAllComplete;
	                                        }else{
	                                        	 field.templateOptions.options = [];
	                                        }
	                                    } else {
	                                        field.templateOptions.options = [];
	                                    }
	                                }
	                            }}
	
	                    ],
	                    alerts: [],
	                    submitModal: function () {
	                    }
	                }
	
	                var submitInitDossier = function () {
	                    this.form.$setSubmitted();
	
	                    if(this.form.$valid) {
	                        initDossierModal.close();
	                        $state.go('annexa.tram.newdossier', {
	                            procedure: this.model.procedure,
	                            register: vm.registerEntry,
	                            diligence: vm.registerEntryDiligence
	                        });
	                    }
	                }
	
	                AnnexaFormlyFactory.showAnnexaFormModal('modalInitDossier', initDossierModal, submitInitDossier);
            	}).catch(function(error) {
            		DialogsFactory.error('global.reg.literals.initExpError');
                });
    
            }

            // adjuntar un expediente
            vm.attachDossier = function () {
                if((!vm.thirdBox.content || vm.thirdBox.content.length == 0) && (!vm.documentBox.content || vm.documentBox.content.length == 0)) {
                    DialogsFactory.error('global.reg.literals.attachExpErrorLength');
                } else {
                    var attachDossierReceivers = function () {
                        var receivers = [];

                        _.forEach(vm.thirdBox.content, function(item) {
                            receivers.push({
                                id: item.third.id,
                                allName: '<span style="display:inline">' + ThirdFactory.getThirdCompleteName(item.third) + ' <span class="label label-xxs">' + ThirdFactory.getRelatedThirdType(item) + '</span></span>'
                            });
                        });

                        return receivers;
                    }

                    var attachDossierDossiers = function () {
                        var dossiers = [];

                        _.forEach(vm.dossierBox.content, function (item) {
                            if(!item.closed) {
                                var dossierTransactions = [];

                                _.forEach(item.dossierTransactions, function(dt) {
                                    dossierTransactions.push({
                                        id: dt.id,
                                        language1: dt.language1,
                                        language2: dt.language2,
                                        language3: dt.language3,
                                        actual: dt.actual
                                    });
                                });

                                dossiers.push({
                                    id: item.id,
                                    allSubject: item.dossierNumber + (item.subject ? ' ' + item.subject : ''),
                                    dossierTransactions: dossierTransactions
                                });
                            }
                        });

                        return dossiers;
                    }

                    var attachDossierDossiersAdvancedSearch = function (e) {
                        e.preventDefault();
                        var advacendSearchModal = AnnexaBoxDossierFactory.getAdvancedSearchModal(vm.content);
                        advacendSearchModal.tableDefinition.callToAdd = { closed: false };
                        advacendSearchModal.multiple = false;

                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDossiersAttach', advacendSearchModal);
                    }
                    var documentToAttach = angular.copy(vm.documentBox.content);
                    if(documentToAttach && documentToAttach.length > 0){
                    	documentToAttach = $linq(documentToAttach).select("x => x.document").toArray();
                    }
                    
                    var attachDossierModal = {
                        title: 'global.reg.literals.attachExp',
                        size: '',
                        icon: 'fa fa-paperclip',
                        fieldsDefinition: [
                            { type: 'field', id: 'receivers', fieldType: 'select_multiple_html', colClass: 'daughter-label-strong', labelProp: 'allName', data: attachDossierReceivers(), label: 'global.literals.remitents' },
                            { type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-select-documents',
                                data: {
                                    title: 'global.literals.documents',
                                    documents: documentToAttach	
                                }
                            },
                            { type: 'field', id: 'dossier', fieldType: 'select_advanced_search', colClass: 'daughter-label-strong', labelProp: 'allSubject', data: attachDossierDossiers(), label: 'global.literals.dossier', advancedSearch: attachDossierDossiersAdvancedSearch, required: true,
                                controller: ['$scope', function($scope) {
                                    $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                                        if(args.id == 'modalASDossiersAttach') {
                                            if(args.selected && args.selected.length > 0 && $linq($scope.to.options).count("x => x.id == " + args.selected[0].id) == 0) {
                                            	var ids = $linq(args.selected).select("x=> x.id").toArray();
                                            	RegFactory.getDossierTransactionByDossierId(ids).then(function(data) {
                                                    if(data && data.length > 0 && data[0].id == args.selected[0].id) {
                                                    	$scope.to.options.push({
                                                            id: args.selected[0].id,
                                                            allSubject: args.selected[0].dossierNumber + (args.selected[0].subject ? ' ' + args.selected[0].subject : ''),
                                                            dossierTransactions: data[0].dossierTransactions
                                                        });
                                                    }
                                                	$scope.model.dossier = args.selected[0].id;
                                                	args.modal.close();
                                                }).catch(function (error) {
                                                    console.error(error);
                                                	$scope.model.dossier = args.selected[0].id;
                                                	args.modal.close();
                                                });
                                            }else{
                                            	$scope.model.dossier = args.selected[0].id;
                                            	args.modal.close();
                                            }
                                        }
                                    });
                                }]
                            },
                            { type: 'field', id: 'dossierTransaction', fieldType: 'select', colClass: 'daughter-label-strong', data: [], label: 'global.tram.literals.transactions', required: true,
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    if(scope.model && scope.model.dossier) {
                                        var indexFieldDossierTransaction = $linq(scope.fields).indexOf("x => x.key == 'dossierTransaction'");

                                        if(indexFieldDossierTransaction != -1) {
                                            var fieldDossier = $linq(scope.fields).singleOrDefault(undefined, "x => x.key == 'dossier'");

                                            if(fieldDossier) {
                                                var dossier = $linq(fieldDossier.templateOptions.options).singleOrDefault(undefined, "x => x.id ==" + scope.model.dossier);

                                                if(dossier) {
                                                    scope.fields[indexFieldDossierTransaction].templateOptions.options = $linq(dossier.dossierTransactions).where("x => x.actual").toArray();
                                                }
                                            }
                                        }

                                        return false;
                                    } else {
                                        scope.model.dossierTransactions = [];
                                        return true;
                                    }
                                }
                            }
                        ],
                        alerts: [],
                        submitModal: function () {
                        }
                    }

                    var submitAttachDossier = function() {
                        var self = this;
                        var validExternal = true;

                        this.form.$setSubmitted();

                        if(!this.model.receivers || this.model.receivers.length == 0) {
                            validExternal = false;

                            attachDossierModal.alerts.push({ msg: 'global.errors.attachDossierNoReceivers'});
                        }
                        var selectedDocumentsAux = angular.copy($linq(this.fieldsDefinition[1].data.documents).where("x => x.selected").toArray());
                        var selectedDocuments = [];
                        if(selectedDocumentsAux && selectedDocumentsAux.length > 0){
                        	_.forEach(selectedDocumentsAux, function(doc){
                        		selectedDocuments.push(doc.id);
                        	});
                        }
                        if (this.form.$valid && validExternal) {
                            RegFactory.attachToDossier(vm.registerEntryDiligence, this.model.dossierTransaction, this.model.receivers, selectedDocuments)
                                .then(function(data) {
                                    if(data) {
                                        vm.dossierBox.content.push(data);
                                    }
                                    attachDossierModal.close();
                                    DialogsFactory.notify('global.literals.attachToExpOk');
                                }).catch(function (error) {
                                    console.error(error);
                                    attachDossierModal.alerts.push({ msg: ErrorFactory.getErrorMessage('registerDiligence', 'attachToExp', error.data) });
                            })
                        }
                    }

                    AnnexaFormlyFactory.showAnnexaFormModal('modalAttachDossier', attachDossierModal, submitAttachDossier);
                }
            }

            //endregion
        }],
        bindings: {
            isEdit: '=',
            redirectToList: '=',
            registerEntry: '=?',
            diligenceId: '@?',
            entryType: '@',
            preloadBoxes: '=?',
            isModal: '=',
            fromDossier: '='
        }
    })
    .component('annexaBoxObjectRegEditHeader',{
        templateUrl: './components/reg/annexa-box-object-reg/annexa-box-object-reg-edit-header.html',
        require: {
            registerEntryComponent: '^^annexaBoxObjectReg'
        },
        controller:['$rootScope', '$filter', 'Language', function($rootScope, $filter, Language) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            // region Pending

            vm.showPending = $rootScope.app.configuration.reg_pending.value;

            vm.pendingOptions = [
                { id: true, name: $filter('translate')('global.literals.PENDING') },
                { id: false, name: $filter('translate')('global.literals.closed') }
            ]

            vm.printPending = function(value) {
                var template = '';

                if(value) {
                    template = '<span class="warn label rounded">{{\'global.literals.PENDING\' | translate}</span>}'
                } else {
                    template = '<span>{{\'global.literals.closed\' | translate}</span>}'
                }

                return template;
            }

            //endregion

            //region Managed

            vm.managedOptions = [
                { id: true, name: $filter('translate')('global.reg.literals.managed') },
                { id: false, name: $filter('translate')('global.reg.literals.noManaged') }
            ];

            vm.printManaged = function (value) {
                var template = '';

                template += value ? $filter('translate')('global.reg.literals.managed') : $filter('translate')('global.reg.literals.noManaged');

                return template;
            }

            //endregion
        }]
    })